import React, { useCallback, useEffect, useMemo, useReducer, useState } from 'react'
import { Grid } from '@material-ui/core'
import { Button, Datagrid, Typography } from '@refera/ui-web'
import { navigate, useLocation, useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'

import { getClassification } from '_modules/classification/actions'
import {
  classificationCountSelector,
  classificationPageSelector,
  classificationPageSizeSelector,
  classificationsSelector,
  classificationsServiceOrderSelector,
} from '_modules/classification/selectors'
import { usePrevious } from '_hooks/use-previous'
import { ROUTES } from '_utils/constants'

import useStyles, { dataGridStyle } from './styles'
import { COLUMNS } from './constants'
import { reducer, INITIAL_STATE, ACTIONS } from './reducer'
import { AddClassificationModal } from '_/components/accordion/add-provider/add-classification'
import { getServiceOrder } from '_modules/service-orders/actions'

const ClassificationManagementView = () => {
  const dispatch = useDispatch()
  const styles = useStyles()
  const [isLoading, setIsLoading] = useState(true)
  const wasLoading = usePrevious(isLoading)
  const { agencyId, serviceOrderId } = useParams()
  const { pathname } = useLocation()

  const [numberEditClassification, setNumberEditClassification] = useState(null)
  const [openClassificationModal, setOpenClassificationModal] = useState(false)
  const [openEditClassificationModal, setOpenEditClassificationModal] = useState(false)

  const classificationsByAgency = useSelector(classificationsSelector)
  const classificationsByServiceOrder = useSelector(classificationsServiceOrderSelector)
  const classificationCount = useSelector(classificationCountSelector)
  const classificationPage = useSelector(classificationPageSelector)
  const classificationPageSize = useSelector(classificationPageSizeSelector)
  const [localState, localDispatch] = useReducer(reducer, INITIAL_STATE)

  const [agency, setAgency] = useState(null)

  const classifications = useMemo(() => {
    if (pathname?.includes(ROUTES.AGENCY)) {
      return classificationsByAgency
    }

    return classificationsByServiceOrder
  }, [pathname, classificationsByAgency, classificationsByServiceOrder])

  useEffect(() => {
    if (!agencyId) {
      dispatch(getServiceOrder(serviceOrderId)).then(res => setAgency(res?.agency?.id))
    }
    return setIsLoading(false)
  }, [agencyId, serviceOrderId])

  const title = useMemo(() => {
    if (pathname?.includes(ROUTES.AGENCY)) {
      return 'Classificações'
    }

    return 'Classificação de um chamado'
  }, [pathname])

  const rows = useMemo(() => classifications ?? [], [classifications])

  const handleGoToNewClassification = useCallback(() => {
    if (pathname?.includes(ROUTES.AGENCY)) {
      return navigate(`${ROUTES.AGENCY}/${agencyId}${ROUTES.CLASSIFICATION_FORM}`)
    }

    return setOpenClassificationModal(true)
  }, [])

  const handleGoToClassification = useCallback(event => {
    if (pathname?.includes(ROUTES.AGENCY)) {
      return navigate(`${ROUTES.AGENCY}/${agencyId}${ROUTES.CLASSIFICATION_FORM}/${event.row?.id}`)
    }

    setNumberEditClassification(event.row?.id)

    return setOpenEditClassificationModal(true)
  }, [])

  const handlePageChange = useCallback(page => {
    localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { page } })
  }, [])

  const handlePageSizeChange = useCallback(pageSize => {
    localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { pageSize } })
  }, [])

  const handleSortModelChange = useCallback(orderObj => {
    if (orderObj[0]?.field && orderObj[0]?.sort) {
      const { field, sort } = orderObj[0]
      const order = `${sort === 'desc' ? '-' : ''}${field}`

      localDispatch({ type: ACTIONS.UPDATE_STATE, payload: { order } })
    }
  }, [])

  const getClassificationsToList = useCallback(() => {
    if (pathname?.includes(ROUTES.AGENCY)) {
      dispatch(
        getClassification({
          ...localState,
          page: localState.page + 1,
          agency: agencyId,
          notRefera: true,
        })
      )

      return setIsLoading(false)
    }

    dispatch(
      getClassification({
        ...localState,
        page: localState.page + 1,
        serviceOrder: serviceOrderId,
      })
    )

    return setIsLoading(false)
  }, [localState, agencyId, serviceOrderId])

  useEffect(() => {
    if (!wasLoading) {
      getClassificationsToList()
    }
  }, [wasLoading, localState, agencyId, serviceOrderId])

  return (
    <Grid className={styles.container}>
      <Grid className={styles.header}>
        <Typography variant="h4" component="h1" className={styles.title}>
          {title}
        </Typography>
        <Button onClick={handleGoToNewClassification}>Novo</Button>
      </Grid>

      <Grid className={styles.tableContainer}>
        <Grid className={styles.mainTable}>
          <Datagrid
            paginationMode="server"
            sx={dataGridStyle}
            rowCount={classificationCount}
            onRowClick={handleGoToClassification}
            onPageChange={handlePageChange}
            onPageSizeChange={handlePageSizeChange}
            onSortModelChange={handleSortModelChange}
            page={classificationPage}
            pageSize={classificationPageSize > 100 ? 100 : classificationPageSize}
            rows={rows}
            columns={COLUMNS}
          />
        </Grid>
      </Grid>
      {!pathname?.includes(ROUTES.AGENCY) && (
        <AddClassificationModal
          openClassificationModal={openClassificationModal}
          setOpenClassificationModal={setOpenClassificationModal}
          agencyId={agencyId || agency}
          serviceOrderId={serviceOrderId}
          title="Classificação de um chamado"
          numberEditClassification={null}
          getClassificationsToList={getClassificationsToList}
        />
      )}

      {!pathname?.includes(ROUTES.AGENCY) && (
        <AddClassificationModal
          openClassificationModal={openEditClassificationModal}
          setOpenClassificationModal={setOpenEditClassificationModal}
          agencyId={agencyId || agency}
          serviceOrderId={serviceOrderId}
          title="Classificação de um chamado"
          numberEditClassification={numberEditClassification}
          getClassificationsToList={getClassificationsToList}
        />
      )}
    </Grid>
  )
}

export default ClassificationManagementView
